.table {
  border: 2px solid @border-color-base;
  border-radius: @table-border-radius-base;
  &-filters {
    &-main {
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
    &-collapse {
      max-height: 0;
      transition: all 600ms ease-in-out;
      &-container {
        padding: 20px;
        border-top: 1px solid @border-color-base;
        background-color: @component-background;
      }
      &.opened {
        max-height: 1000px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .ant-table {
    table {
      border: 0;
      display: flex;
      width: 100%;
      flex-direction: column;
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      tr {
        border: 1px solid @border-color-split;
        display: block;
        margin-bottom: 0.625em;
      }
      td {
        border-bottom: 1px solid @border-color-split;
        display: block;
        font-size: 0.8em;
        text-align: right;
        &::before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  [dir="rtl"] {
    .ant-table {
      table {
        td {
          text-align: left;
          &::before {
            float: right;
          }
        }
      }
    }
  }
}
